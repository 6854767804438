/* eslint-disable */
/* tslint:disable */
// @ts-ignore
import icon from 'vue-svgicon'
icon.register({
  'play-2': {
    width: 11,
    height: 12,
    viewBox: '0 0 11 12',
    data: '<path pid="0" fill-rule="evenodd" clip-rule="evenodd" d="M1.544 11.884l8.49-5.513c.262-.17.29-.467.06-.662a.582.582 0 00-.058-.044L1.546.117C1.283-.054.885-.035.655.16A.408.408 0 00.5.469V11.53c0 .26.282.469.63.469.152 0 .3-.041.414-.116z" _fill="#fff"/>'
  }
})
